import { createRouter, createWebHistory } from "vue-router";
import auth from '@/middlewares/auth.js'
import store from '../store';

const routes = [
  {
    path: "/",
    component: () => import('@/layouts/defaultLayout.vue'),
    children: [
      { path: "home",                           name: "Home",               component: () => import('@/views/HomeView.vue')                          },
      { path: "cursos/meus-cursos/",            name: "Meus cursos",        component: () => import('@/views/MeusCursosView.vue')                    },
      { path: "cursos/comprar/:id",             name: "Comprar",            component: () => import('@/views/ComprarCursoView.vue')                  },
      { path: "cursos/aulas/:curso/:disciplina",name: "Aulas",              component: () => import('@/views/AulasView.vue'), meta: { breadcrumb: 'Aulas', parent: "Meus cursos" }},
      { path: "sistema/perfilacesso",           name: "Perfil de Acesso",   component: () => import('@/views/PerfilAcesso.vue')                      },
      { path: "sistema/usuarios",               name: "Usuários",           component: () => import('@/views/UsuariosView.vue')                      },
      { path: "sistema/acoes",                  name: "Ações",              component: () => import('@/views/AcoesView.vue')                         },
      { path: "sistema/modulos",                name: "Módulos",            component: () => import('@/views/ModulosView.vue')                       },
      { path: "sistema/paginas",                name: "Páginas",            component: () => import('@/views/PaginasView.vue')                       },
      { path: "sistema/logs",                   name: "Logs",               component: () => import('@/views/LogsView.vue')                          },
      { path: "sistema/codigos-promocionais",    name: "Códigos Promocionais",            component: () => import('@/views/CodigosPromocionaisView.vue')             },
      { path: "sistema/codigos-promocionais",   name: "Códigos Promocionais",component: () => import('@/views/CodigosPromocionaisView.vue')             },
      { path: "usuario/notificacoes",           name: "Notificações",       component: () => import('@/views/NotificacoesView.vue')                  },
      { path: "usuario/documentos",             name: "Documentos",         component: () => import('@/views/DocumentosView.vue')                  },
      { path: "pedagogico/cursos",              name: "Cursos",             component: () => import('@/views/PedagogicoCursosView.vue')              },
      { path: "pedagogico/microcertificacoes",  name: "Microcertificações", component: () => import('@/views/PedagogicoMicrocertificacoesView.vue')  },
      { path: "pedagogico/disciplinas",         name: "Disciplinas",        component: () => import('@/views/PedagogicoDisciplinasView.vue') },
      { path: "pedagogico/disciplinas/:disciplina/aulas", name: "Disciplina Aulas", component: () => import('@/views/PedagogicoAulasView.vue'), meta: { breadcrumb: 'Aulas', parent: "Disciplinas" } },
      { path: "pedagogico/exercicios",        name: "Exercícios",        component: () => import('@/views/PedagogicoExercicios.vue') },
    ]
  },
  {
    path: "",
    meta: { ignoreAuth: true },
    component: () => import('@/layouts/extranetLayout.vue'),
    children: [
      { path: "",                name: '',              component: () => import('@/views/LandingPageView.vue')          },
      { path: "login",           name: 'Login',         component: () => import('@/views/auth/signin/Login.vue')        },
      { path: "register",        name: "Register",      component: () => import('@/views/auth/signup/Register.vue')     },
      { path: "password/email",  name: "SendEmail",     component: () => import('@/views/auth/reset/SendEmail.vue')     },
      { path: "password/reset",  name: "ResetPassword", component: () => import('@/views/auth/reset/ResetPassword.vue') },
      { path: "midias",  name: "Mídias", component: () => import('@/views/MidiasView.vue') },
      { path: "challenge1",  name: "Challenge1", component: () => import('@/views/challenges/Challenge1View.vue') },

      //Rotas de erros
      { path: ":pathMatch(.*)*", name: "404", component: () => import('@/views/auth/error/404.vue') },
      { path: "500",             name: "500", component: () => import('@/views/auth/error/500.vue') }
    ],
  },


];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.afterEach((to) => {
  store.dispatch('updateBreadcrumb', to);
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}


router.beforeEach((to, from, next) => {
  if(to.meta.ignoreAuth) next()
  else{
    to.meta.middleware = to.meta.middleware ? to.meta.middleware.unshift(auth) : [auth];

    const middleware = to.meta.middleware;

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }
});

export default router;